export const beliefs = [
    {
        title: 'Deus',
        slug: 'deus',
        text: 'Deus é amor, poder e esplendor, e Deus é mistério. Os seus caminhos estão muito longe dos nossos, mas ainda assim, ele chega até nós. Deus é infinito mas está perto, sem demora...',
        image: 'https://adventista.es/wp-content/uploads/2019/09/marc-olivier-jodoin-TStNU7H4UEE-unsplash-500x1000.jpg',
        beliefs: [
            {
                order: 1,
                title: 'As Sagradas Escrituras',
                subtitle: 'Na Sua palavra, Deus entregou ao homem o conhecimento necessário para a salvação.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/sixteen-miles-out-L-mNqn89I2s-unsplash-scaled-pyha15ebbbdanv02c7xf441eouptqlvxwuz3qsu61c.jpg',
                url: 'https://institucional.adventistas.org.pt/crencas/as-sagradas-escrituras/'
            },
            {
                order: 2,
                title: 'A Trindade',
                subtitle: 'Há só Deus: Pai, Filho e Espírito Santo, uma unidade de Pessoas co-eternas.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/marc-olivier-jodoin-TStNU7H4UEE-unsplash-scaled-pykgt29tvfnajh60v9g4qfs0yt1jw3anzxjv8yeq6o.jpg',
                url: 'https://institucional.adventistas.org.pt/crencas/a-trindade/'
            },
            {
                order: 3,
                title: 'O Pai',
                subtitle: 'Deus, o Pai eterno é o Criador, a Fonte, Sustentáculo, e Soberano de toda a criação.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Crencas-O-Pai-scaled-q16e5aeenkeszo4liyh82qswsu41tvwxepk7pur6z4.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/o-pai/'
            },
            {
                order: 4,
                title: 'O Filho',
                subtitle: 'Deus, o Filho Eterno encarnou em Jesus Cristo.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/O-Filho-scaled-q14oruvi6vv6wlg71x2x6abpfk48ucmiz1gpvap8nk.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/o-filho/'
            },
            {
                order: 5,
                title: 'O Espírito Santo',
                subtitle: 'Ele atrai e convence os seres humanos',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/O-Espirito-Santo-scaled-q14onraudw969tejznahstkc62bm9vco2r1hius1sg.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/o-espirito-santo/'
            }

        ]
    },
    {
        title: 'A Humanidade',
        slug: 'humanidade',
        text: 'Dos neurónios às nebulosas, do DNA às galáxias distantes, estamos cercados de maravilhas. No entanto, a beleza está arruinada.',
        image: 'https://adventista.es/wp-content/uploads/2019/09/nicole-honeywill-dGxOgeXAXm8-unsplash-500x1000.jpg',
        beliefs: [
            {
                order: 1,
                title: 'A Criação',
                subtitle: 'Deus é o Criador de todas as coisas, e revelou nas Escrituras o relato autêntico da Sua actividade criativa.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Criacao-scaled-pyrf5woyzzjvwfi96m44dkten7vkw6nzhdfge0z28g.jpg',
                url: 'https://institucional.adventistas.org.pt/crencas/as-sagradas-escrituras/'
            },
            {
                order: 2,
                title: 'A Natureza do Homem',
                subtitle: 'O Homem e a Mulher foram criados à imagem de Deus com a individualidade, o poder e a liberdade para pensarem e agirem.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Natureza-do-Homem-scaled-pyrf4lp1i9rjrxeit1srwwocxyb87bhemwt6e6wqvk.jpg',
                url: 'https://institucional.adventistas.org.pt/crencas/a-trindade/'
            }
        ]
    },
    {
        title: 'A Salvação',
        slug: 'salvacao',
        text: 'O amor. A harmonia. A perfeição. Houve um tempo em que toda a criação cantava a mesma canção gloriosa.',
        image: 'https://adventista.es/wp-content/uploads/2019/09/joshua-earle-9idqIGrLuTE-unsplash-500x1000.jpg',
        beliefs: [
            {
                order: 1,
                title: 'O Grande Conflito',
                subtitle: 'Toda a humanidade está agora envolvida no grande conflito entre Cristo e Satanás, a respeito do carácter de Deus, da Sua lei, e da Sua soberania sobre todo o universo.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Crencas-O-Grande-Conflito-scaled-pyrehr89e8hnl4l77oa7t6815sx245s9ttzbh2s840.jpg',
                url: 'https://institucional.adventistas.org.pt/crencas/o-grande-conflito/'
            },
            {
                order: 2,
                title: 'A Vida, Morte e Ressurreição de Cristo',
                subtitle: 'A morte de Cristo é substitutiva e expiatória, reconciliadora e transformadora.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/A-Vida-Morte-e-Ressurreicao-de-Cristo-scaled-q14q2jixmnrvjdmyg3jv0i7vki0j55yle3wvy1hbm8.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/vida-morte-e-ressureicao-de-cristo/'
            },
            {
                order: 3,
                title: 'A Experiência da Salvação',
                subtitle: 'Através de Cristo nós somos justificados, adoptados como filhos e filhas de Deus, e somos libertos do domínio do pecado.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/A-experiencia-da-Salvacao-scaled-q14p48f06ct3rrh0s9o305w707486vraoaovcgcmq8.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/a-experiencia-da-salvacao/'
            }
        ]
    },
    {
        title: 'A Igreja',
        slug: 'igreja',
        text: 'A igreja é a comunidade de crentes que confessam que Jesus Cristo é o Senhor e Salvador. Como continuadores do povo de Deus do Antigo Testamento, somos convidados a deixar o mundo; e nos reunimos para adorar...',
        image: 'https://adventista.es/wp-content/uploads/2019/09/marc-olivier-jodoin-TStNU7H4UEE-unsplash-500x1000.jpg',
        beliefs: [
            {
                order: 1,
                title: 'Crescer em Cristo',
                subtitle: 'A vitória de Jesus dá-nos poder sobre as forças do mal, que ainda procuram controlar-nos, enquanto com Ele caminhamos em paz, alegria, e certeza do Seu amor.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Unidade-do-Corpo-de-Cristo-scaled-q14o9npzvcyc4dvubjvyednfdztetbdm4yradfon4g.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/unidade-do-corpo-de-cristo/'
            },
            {
                order: 2,
                title: 'A Igreja',
                subtitle: 'A igreja é a comunidade de crentes que confessam Jesus Cristo como Senhor e Salvador.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/A-Igreja-scaled-q14oi3bv6uhyafmu2p4g9r1b9g8yvjuwyph4dt6hao.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/a-igreja/'
            },
            {
                order: 3,
                title: 'O Remanescente e a sua Missão',
                subtitle: 'Nos últimos dias, num tempo de apostasia generalizada, um remanescente foi chamado para guardar os mandamentos de Deus e a fé de Jesus.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/O-Remanescente-scaled-q16fnhrcrmm2i5e50dypv2du0qy1pj3ouzw80adw5s.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/o-remanescente-e-a-sua-missao/'
            },
            {
                order: 4,
                title: 'A Unidade do Corpo de Cristo',
                subtitle: 'A igreja é um corpo com muitos membros, chamados de todas as nações, raças, línguas e povos.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Unidade-do-Corpo-de-Cristo-scaled-q14o9npzvcyc4dvubjvyednfdztetbdm4yradfon4g.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/unidade-do-corpo-de-cristo/'
            },
            {
                order: 5,
                title: 'O Batismo',
                subtitle: 'Pelo batismo confessamos a nossa fé na morte e ressurreição de Jesus Cristo, e testemunhamos a nossa morte para o pecado e o nosso propósito de viver uma nova vida.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/O-Batismo-scaled-q16fmy0qs3v1qc6t7nfjwpd5jnnc7vxbsa70xh75sg.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/o-batismo/'
            },
            {
                order: 6,
                title: 'A Santa Ceia',
                subtitle: 'A Santa Ceia é a participação nos emblemas do corpo e do sangue de Jesus como uma expressão de fé n’Ele, o nosso Senhor e Salvador.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/A-Santa-Ceia-scaled-q14of8vghwlp4xrnoyu47yw2ig9ykjk68md72nee4w.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/a-santa-ceia/'
            },
            {
                order: 7,
                title: 'Dons Espirituais e Ministérios',
                subtitle: 'Deus concede a todos os membros da Sua igreja, em todas as épocas, dons espirituais que cada membro deve empregar em ministério de amor para o bem da igreja e da humanidade.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Dons-Espirituais-scaled-q14ovctvmonm4cd8mfixgejh16vfhsik4cvs6dihhs.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/dons-espirituais-e-ministerios/'
            },
            {
                order: 8,
                title: 'O Dom de Profecia',
                subtitle: 'Um dos dons do Espírito Santo é o dom de profecia. Este dom é uma marca identificativa da igreja remanescente e foi manifestado no ministério de Ellen G. White.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/O-Dom-de-Profecia-scaled-q16g0v0pyswvmhz2y5zpboxu611z6k69d5wtpykbo0.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/o-dom-de-profecia/'
            }
        ]
    },
    {
        title: 'Vida Cristã',
        slug: 'vida-crista',
        text: 'Nos dez mandamentos, a lei de Deus nos mostra como viver e revela nossa necessidade de Cristo.',
        image: 'https://adventista.es/wp-content/uploads/2019/09/dan-gold-4_jhDO54BYg-unsplash-500x1000.jpg',
        beliefs: [
            {
                order: 1,
                title: 'A Lei de Deus',
                subtitle: 'Os grandes princípios da Lei de Deus estão incorporados nos Dez Mandamentos e foram exemplificados na vida de Cristo.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/A-Lei-de-Deus-scaled-q14nwsbka1bz4mkyhhkvr4l6j19gej9y194ws0rqc0.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/a-lei-de-deus/'
            },
            {
                order: 2,
                title: 'O Sábado',
                subtitle: 'O beneficente Criador, após os seis dias da criação, descansou no sétimo dia e instituiu o Sábado para todas as pessoas como um memorial da criação.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/O-Sabado-scaled-q14nho30cymyeejhrgbw5fxakxrxls9cyferwt6oeo.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/o-sabado/'
            },
            {
                order: 3,
                title: 'Mordomia',
                subtitle: 'Nós somos os mordomos de Deus, que nos confiou tempo e oportunidades, capacidades e posses, e as bênçãos da terra e os seus recursos.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Mordomia-scaled-q14nmg3h3n6ff1lmv0ombqinag83qe88m2smqg3ms0.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/mordomia/'
            },
            {
                order: 4,
                title: 'Comportamento Cristão',
                subtitle: 'Nós somos chamados a ser pessoas devotas que pensam, sentem e agem em harmonia com os princípios do céu.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Comportamento-Cristao-scaled-q14np3z0gqtob9qdb64ke2bnvr3jjisuz9c5oo5h5c.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/comportamento-cristao/'
            },
            {
                order: 5,
                title: 'Casamento e Família',
                subtitle: 'O casamento foi divinamente instituído no Éden e foi confirmado por Jesus para ser uma união vitalícia entre um homem e uma mulher num companheirismo acompanhado de amor.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Casamento-e-Familia-scaled-q14n56s5m5jw8so4n2481laymrahegpnwnkoijov0w.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/casamento-e-familia/'
            },
        ]
    },
    {
        title: 'Acontecimentos Finais',
        slug: 'acontecimentos-finais',
        text: 'Do Jardim do Éden à Torre de Babel, da destruição de Sodoma ao Êxodo do Egito, Deus sempre investigou antes de agir.',
        image: 'https://adventista.es/wp-content/uploads/2019/09/patrick-fore-b_SHPU5M3nk-unsplash-500x1000.jpg',
        beliefs: [
            {
                order: 1,
                title: 'O Ministério de Cristo no Santuário Celestial',
                subtitle: 'Existe um santuário no céu, o verdadeiro santuário que o Senhor fez e não o homem.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/O-Ministerio-de-Cristo-scaled-q14oxf34mlh9ljddktmgfdsvympfdvpcqkf538gdu8.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/o-ministerio-de-cristo-no-santuario-celestial/'
            },{
                order: 2,
                title: 'A Segunda Vinda de Cristo',
                subtitle: 'A Segunda Vinda é a bendita esperança da igreja, o ponto mais alto do evangelho. A vinda do Salvador será literal, pessoal, visível e mundial.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/A-Segunda-Vinda-scaled-q14pa136bkqrd71wxrv9dp8ivqjpnorpgzinvvr8cw.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/a-segunda-vinda-de-cristo/'
            },{
                order: 3,
                title: 'Morte e Ressurreição',
                subtitle: 'O salário do pecado é a morte. Mas Deus, que é imortal, concederá vida eterna aos Seus redimidos.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/Morte-e-Ressurreicao-q14ph1xre0cw4aumxb5wiffiodxg69ni3r09zbcbv4.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/morte-e-ressurreicao/'
            },{
                order: 4,
                title: 'O Milénio e o Fim do Pecado',
                subtitle: 'O milénio é um reinado de mil anos de Cristo com os Seus santos no céu, entre a primeira e a segunda ressurreições.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/O-Milenio-scaled-q14pc4a9iblp600oqodex69eek92rh28fbpia0nqj4.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/milenio-e-o-fim-do-pecado/'
            },{
                order: 5,
                title: 'A Nova Terra',
                subtitle: 'Na nova terra, na qual habita a justiça, Deus providenciará um eterno lar para os redimidos e um perfeito ambiente para a vida eterna, amor, alegria e aprendizagem na Sua presença.',
                image: 'https://institucional.adventistas.org.pt/wp-content/uploads/elementor/thumbs/A-Nova-Terra-scaled-q14p0scb483978h8ws1bv17clc3ryu2q78krzxgljk.jpeg',
                url: 'https://institucional.adventistas.org.pt/crencas/a-nova-terra/'
            },
        ]
    },
]