import React from "react";
import { channels } from "../../data/Channels";
import MediaBlock from "../blocks/MediaBlock";
import {
  BrandFacebook,
  BrandInstagram,
  BrandYoutube,
} from "tabler-icons-react";
import SmallMediaBlock from "../blocks/SmallMediaBlock";

function ChannelsPage() {
  return (
    <div>
      <div className="px-4 md:px-20 mb-32">
        <div id="medias">
          {channels
            .filter((block) => !block.small)
            .map((block) => (
              <MediaBlock {...block} />
            ))}
        </div>
      </div>
      <div className="px-4 md:px-20 mb-32 bg-slate-200 py-4">
        <div id="small" className="mt-20 mb-32 grid md:grid-cols-3 gap-12">
          {channels
            .filter((block) => block.small)
            .map((block) => (
              <SmallMediaBlock {...block} />
            ))}
        </div>
      </div>
      <div className="px-4 md:px-20 mb-32">
        <div id="news" className="mb-16">
          <h1 className="text-3xl md:text-5xl font-bold mb-12">Na Comunicação Social</h1>
          <div className="flex flex-row gap-8">
            <div className="aspect-square h-24 md:h-40 bg-[#f2e928] flex flex-col overflow-hidden shadow-2xl shadow-[#f2e928] rounded-xl justify-center items-center p-5 text-xl">
              <a
                className=""
                href="https://www.rtp.pt/play/p50/a-fe-dos-homens"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Logo RTP2"
                  src="https://media.rtp.pt/empresa/wp-content/uploads/sites/31/2015/07/RTP2_positivo_horiz_RGB.png"
                />
              </a>
            </div>
            <div className="aspect-square h-24 md:h-40 bg-[#e5262b] flex flex-col shadow-[#e5262b] shadow-2xl rounded-xl justify-center items-center p-5 text-xl">
              <a
                href="https://www.rtp.pt/play/p505/a-fe-dos-homens"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  alt="Logo Antena 1"
                  src="https://media.rtp.pt/empresa/wp-content/uploads/sites/31/2015/07/Antena1_negativo_horiz_RGB.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div id="socialmedia">
          <h1 className="text-3xl md:text-5xl font-bold mt-24 mb-12">Nas Redes Sociais</h1>
          <div className="flex flex-row gap-8">
            <div className="h-24 md:h-40 bg-gradient-to-tr from-[#fcaf45] via-[#833ab4] to-[#405de6] text-white aspect-square flex flex-col shadow-2xl shadow-[#833ab4] rounded-xl justify-center items-center text-lg">
              <a
                href="https://instagram.com/igrejaadventistapt"
                target="_blank"
                rel="noreferrer"
                className="flex flex-col justify-center items-center"
              >
                <BrandInstagram strokeWidth={1} className="w-full h-8 md:h-20" />
                <span className="text-center w-full">Instagram</span>
              </a>
            </div>
            <div className="h-24 md:h-40 bg-[#4267B2] text-white aspect-square flex flex-col shadow-2xl shadow-[#4267B2] rounded-xl justify-center items-center text-lg">
              <a
                href="https://facebook.com/igrejaadventistapt"
                target="_blank"
                rel="noreferrer"
                className="flex flex-col justify-center items-center"
              >
                <BrandFacebook strokeWidth={1} className="w-full h-8 md:h-20" />
                <span>Facebook</span>
              </a>
            </div>
            <div className="h-24 md:h-40 bg-[#FF0000] text-white aspect-square flex flex-col shadow-2xl shadow-[#FF0000] rounded-xl justify-center items-center text-lg">
              <a
                href="https://www.youtube.com/@igrejaadventistapt"
                target="_blank"
                rel="noreferrer"
                className="flex flex-col justify-center items-center"
              >
                <BrandYoutube strokeWidth={1} className="w-full h-8 md:h-20" />
                <span>Youtube</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChannelsPage;
