import React, { useState, useRef, useEffect } from "react";
import { Transition } from "react-transition-group";
import {
  Bible,
  BuildingChurch,
  Cross,
  School,
  WorldWww,
} from "tabler-icons-react";
import axios from "axios";
import Lottie from "lottie-react";
import successAnimation from "../../animations/success.json";
import ReactGA from "react-ga4";

export const FORM_STATUS = {
  READY: "ready",
  SUBMITTED: "submitted",
  DONE: "done",
  ERROR: "error",
};

const duration = 300;
const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  opacity: 0,
  height: 0,
};
const transitionStyles = {
  entering: { opacity: 0, height: "0%", display: "block" },
  entered: { opacity: 1, height: "auto" },
  exiting: { opacity: 0, height: 0 },
  exited: { opacity: 0, height: 0, display: "none" },
};

function NextStepPage() {
  const [selected, setSelected] = useState();
  const [state, setState] = useState({
    selected: undefined,
    name: "",
    phone: "",
    message: "",
    email: "",
    address: "",
    submitted: FORM_STATUS.READY,
  });

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  const handleOption = (event) => {
    event.stopPropagation();
    let selected;

    if (event.target === event.currentTarget) {
      if (event.target.dataset.url) {
        window.open(event.target.dataset.url)
        ReactGA.event({
          category: "next-steps",
          action: event.target.dataset.url
        });
        return;
      }

      ReactGA.event({
        category: "next-steps",
        action: event.target.dataset.option
      });

      setState((state) => ({
        ...state,
        ...{ selected: event.target.dataset.option },
      }));
      document.querySelector('#anchor').scrollIntoView({ behavior: "smooth" });
    } else {
      if (event.target.parentElement.dataset.url) {
        window.open(event.target.parentElement.dataset.url);
        ReactGA.event({
          category: "next-steps",
          action: event.target.parentElement.dataset.url
        });
        return;
      }

      ReactGA.event({
        category: "next-steps",
        action: event.target.parentElement.dataset.option
      });
      setState((state) => ({
        ...state,
        ...{ selected: event.target.parentElement.dataset.option },
      }));
      document.querySelector('#anchor').scrollIntoView({ behavior: "smooth" });
    }


  };

  const handleSubmit = function (e) {
    e.preventDefault();
    let data;
    switch (state.selected) {
      case "bible":
        data = document.querySelector("#form-bible");
        break;
      case "bible-study":
        data = document.querySelector("#form-bible-study");
        break;
      case "prayer":
        data = document.querySelector("#form-prayer");
        break;
      default:
        break;
    }
    setState((state) => ({
      ...state,
      ...{ submitted: FORM_STATUS.SUBMITTED },
    }));

    // Receba uma biblia
    axios
      .post("https://universitariosadventistas.pt/api/index.php", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setState((state) => ({ ...state, ...{ submitted: FORM_STATUS.DONE } }));
        e.target.reset();
      });

  };

  const handleAnimationComplete = (e) => {
    setState((state) => ({ ...state, ...{ submitted: FORM_STATUS.READY } }));
  };

  const baseOptionCss = `flex flex-col items-center justify-center rounded-lg flex-1 aspect-square md:w-52 md:h-40 px-4 text-center bg-white font-semibold cursor-pointer  border-2 transition hover:opacity-100 hover:scale-105`;
  const activeOptionCss = `opacity-100 border-[#5da8e1] scale-110 shadow-lg bg-[hsl(206,69%,98%,1)] text-[#5da8e1]`;
  const defaultOptionCss = `opacity-50 border-cocoa-400`;

  const options = [
    {
      id: "bible",
      icon: <Bible size={48} strokeWidth={1} />,
      text: "Receba uma Bíblia",
    },
    {
      id: "visit",
      icon: <BuildingChurch size={48} strokeWidth={1} />,
      text: "Venha visitar-nos!",
      url: 'https://igrejas.adventistas.org.pt'
    },
    {
      id: "prayer",
      icon: <Cross size={48} strokeWidth={1} />,
      text: "Partilhe um pedido de oração",
    },
    {
      id: "bible-study",
      icon: <School size={48} strokeWidth={1} />,
      text: "Estude a Bíblia acompanhado",
    },
    {
      id: "bible-online",
      icon: <WorldWww size={48} strokeWidth={1} />,
      text: "Estude a Bíblia online",
      url: 'https://cursos.novotempo.pt'
    },
  ];

  function renderOptions(
    options,
    selected,
    handleOption,
    baseOptionCss,
    activeOptionCss,
    defaultOptionCss
  ) {
    return options.map((option) => (
      <div
        key={option.id}
        onClick={handleOption}
        data-url={option.url}
        data-option={option.id}
        className={`${baseOptionCss} ${
          state.selected === option.id ? activeOptionCss : defaultOptionCss
        }`}
      >
        {option.icon}
        <span>{option.text}</span>
      </div>
    ));
  }

  const renderedOptions = renderOptions(
    options,
    selected,
    handleOption,
    baseOptionCss,
    activeOptionCss,
    defaultOptionCss
  );

  return (
    <div className="px-4 md:px-20 mb-32">
      <h1 className="text-4xl mt-12 mb-12 font-bold">Temos algo para si</h1>
      <p className="mb-16">
        Descubra o que temos reservado para si! Preencha o formulário abaixo e
        escolha o seu próximo passo. Não perca a chance de explorar algo novo e
        emocionante. Entre em contacto connosco e comece a sua jornada. Estamos
        ansiosos para partilhar algo incrível consigo!
      </p>
      <h2 className="text-2xl font-medium mb-8">O que deseja fazer?</h2>
      <div className="flex flex-col rounded-3xl gap-4">
        <div className="w-full flex flex-row gap-4 rounded-3xl flex-wrap">
          {renderedOptions}
        </div>
      </div>
      <span id="anchor"/>
      <Transition
        in={state.selected === "bible"}
        timeout={duration}
      >
        {(transitionState) => (
          <div
            className="mt-8"
f            style={{
              ...defaultStyle,
              ...transitionStyles[transitionState],
            }}
          >
            <h2 id="bible" className="text-2xl font-medium mb-8 mt-16">
              Para receber a sua Bíblia partilhe connosco:
            </h2>
            <div className="mb-16 bg-red-50 p-4 w-fit"><span className="font-bold text-red-600 block">Importante</span>Apenas moradas no território Português. 🇵🇹</div>
            <div className="flex flex-col rounded-3xl gap-4">
              <form className="w-full md:w-1/2" onSubmit={handleSubmit} id="form-bible">
                <input type="hidden" name="target" value="bible" />
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/4">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-full-name"
                    >
                      Nome
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cocoa-200"
                      id="inline-full-name"
                      name="name"
                      type="text"
                      autoComplete="name"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/4">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-full-name"
                    >
                      Email
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cocoa-200"
                      id="inline-full-name"
                      name="email"
                      type="text"
                      autoComplete="email"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-start mb-6">
                  <div className="md:w-1/4">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-full-name"
                    >
                      Telefone
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cocoa-200"
                      id="inline-full-name"
                      name="phone"
                      type="text"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/4">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-full-name"
                    >
                      Morada
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <textarea
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full h-48 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cocoa-200"
                      id="inline-full-name"
                      name="address"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-start mb-6">
                  <div className="md:w-1/4"></div>
                  <div className="md:w-3/4">
                    <button
                      type="submit"
                      className="bg-[#5da8e1] text-white appearance-none rounded py-2 px-4 transition hover:shadow-lg"
                    >
                      {state.submitted === FORM_STATUS.SUBMITTED
                        ? "A enviar..."
                        : "Enviar"}
                    </button>
                    {state.submitted === FORM_STATUS.DONE && (
                      <span className="text-green-400 ml-4">
                        Pedido enviado!
                      </span>
                    )}
                    {state.submitted === FORM_STATUS.DONE && (<Lottie animationData={successAnimation} onComplete={handleAnimationComplete} loop={false} style={{position: 'fixed', top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)'}} />)}
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Transition>
      <Transition
        in={state.selected === "visit"}
        timeout={duration}
      >
        {(state) => (
          <div
            className="mt-8"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <h2 id="bible" className="text-2xl font-medium mb-8">
              Encontre a igreja mais próxima de si
            </h2>
            <div className="flex flex-col rounded-3xl gap-4">
              Quer fazer parte de uma comunidade vibrante e cheia de esperança?
              Clique no link abaixo para encontrar a igreja adventista do sétimo
              dia mais próxima e junte-se a nós na busca por uma vida plena de
              significado e propósito!{" "}
              <a
                className="bg-orange-50 p-4 font-semibold mt-4"
                href="https://igrejas.adventistas.org.pt"
                target="_blank"
                rel="noreferrer"
              >
                https://igrejas.adventistas.org.pt
              </a>
            </div>
          </div>
        )}
      </Transition>
      <Transition
        in={state.selected === "prayer"}
        timeout={duration}
      >
        {(state) => (
          <div
            className="mt-8"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <h2 id="bible" className="text-2xl font-medium mb-8">
              Escreva o seu motivo
            </h2>
            <div className="flex flex-col rounded-3xl gap-4">
              <p>
                Partilhe connosco os seus pedidos de oração e faça parte de uma
                comunidade de fé e esperança. Vamos unir-nos numa rede de apoio
                e intercessão.
              </p>
              <form className="w-full md:w-1/2" onSubmit={handleSubmit} id="form-prayer">
                <input type="hidden" name="target" value="prayer" />
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/4">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-full-name"
                    >
                      Nome
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-slate-500"
                      id="inline-full-name"
                      name="name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-start mb-6">
                  <div className="md:w-1/4">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-full-name"
                    >
                      Pedido
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <textarea
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full h-48 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cocoa-200"
                      id="inline-full-name"
                      name="message"
                      type="text"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-start mb-6">
                  <div className="md:w-1/4"></div>
                  <div className="md:w-3/4">
                    <button
                      type="submit"
                      className="bg-[#5da8e1] appearance-none text-white rounded py-2 px-4 transition hover:shadow-lg"
                    >
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Transition>
      <Transition
        in={state.selected === "bible-study"}
        timeout={duration}
      >
        {(state) => (
          <div
            className="mt-8"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <h2 id="bible" className="text-2xl font-medium mb-8">
              Vamos ajudá-lo a compreender a Bíblia!
            </h2>
            <div className="flex flex-col rounded-3xl gap-4">
              <p>
                Partilhe connosco os seus contacto para que possamos ajudá-lo
                nesta jornada de descoberta.
              </p>
              <form className="w-full md:w-1/2" onSubmit={handleSubmit}>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/4">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-full-name"
                    >
                      Nome
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-slate-500"
                      id="inline-full-name"
                      type="text"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/4">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-full-name"
                    >
                      Email
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-slate-500"
                      id="inline-full-name"
                      type="email"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-6">
                  <div className="md:w-1/4">
                    <label
                      className="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                      htmlFor="inline-full-name"
                    >
                      Telefone
                    </label>
                  </div>
                  <div className="md:w-3/4">
                    <input
                      className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-slate-500"
                      id="inline-full-name"
                      type="tel"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-start mb-6">
                  <div className="md:w-1/4"></div>
                  <div className="md:w-3/4">
                    <button
                      type="submit"
                      className="bg-[#5da8e1] appearance-none text-white rounded py-2 px-4 transition hover:shadow-lg">
                      Enviar
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
      </Transition>
      <Transition
        in={state.selected === "bible-online"}
        timeout={duration}
      >
        {(state) => (
          <div
            className="mt-8"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <h2 id="bible" className="text-2xl font-medium mb-8">
              Escola Bíblica Novo Tempo Portugal
            </h2>
            <div className="flex flex-col rounded-3xl gap-4">
              Um estudo autónomo, sem pressões, ao seu ritmo e com facilidade de
              acesso através de qualquer equipamento eletrónico. Acesso a um
              conjunto de ofertas, que poderá desbloquear ao longo do estudo,
              para motivar e premiar o tempo investido em aprender.
              <a
                className="bg-orange-50 p-4 font-semibold mt-4"
                href="https://cursos.novotempo.pt"
                target="_blank"
                rel="noreferrer"
              >
                cursos.novotempo.pt
              </a>
            </div>
          </div>
        )}
      </Transition>
    </div>
  );
}

export default NextStepPage;
