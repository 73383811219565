import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Menu2, X } from "tabler-icons-react";
import Logo from "./Logo";

function Header() {
  const activeLinkStyle =
    "font-medium relative border-b-2 box-border border-orange-300";
  const LinkStyle =
    "font-medium relative border-b-2 box-border border-transparent hover:border-orange-100";

  const toggleMenu = () => {
    var menu = document.getElementById("menu");
    menu.classList.toggle("translate-x-full");
    menu.classList.toggle("w-2/3");
  };

  return (
    <div className="mx-auto h-24 px-0 md:px-12 lg:px-16 max-w-[2024px]">
      <div className="container px-4 md:px-0 h-full mx-auto flex justify-between items-center">
        <Link to={"/"} className="z-10 relative font-medium">
          <Logo />
        </Link>
        <button
          onClick={toggleMenu}
          type="button"
          className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden bg-transparent focus:outline-none focus:ring-0 dark:text-gray-400"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Abrir menu</span>
          <Menu2 />
        </button>
        <nav
          id="menu"
          className="transform-gpu h-screen md:h-fit translate-x-full md:transform-none fixed top-0 right-0 px-10 py-16 bg-black text-white bg-opacity-80 backdrop-blur-md z-50 items-start gap-4 flex flex-col justify-start w-2/3 md:w-auto md:flex md:flex-row  md:relative md:bg-transparent md:px-0 md:py-0 md:text-black transition md:items-center"
          aria-label="Main"
          onClick={toggleMenu}
        >
          <span className="md:hidden z-90 fixed top-4 right-6">
            <span
              className="text-right text-white text-4xl"
            >
              <X />
            </span>
          </span>
          <NavLink
            to={"fe"}
            className={({ isActive }) =>
              isActive ? activeLinkStyle : LinkStyle
            }
          >
            A nossa fé
          </NavLink>
          <NavLink
            to={"comunidade"}
            className={({ isActive }) =>
              isActive ? activeLinkStyle : LinkStyle
            }
          >
            A nossa comunidade
          </NavLink>
          <NavLink
            to={"canais"}
            className={({ isActive }) =>
              isActive ? activeLinkStyle : LinkStyle
            }
          >
            Os nossos canais
          </NavLink>

          <NavLink
            to={"contactos"}
            className={({ isActive }) =>
              (isActive ? activeLinkStyle : LinkStyle) + " md:hidden"
            }
          >
            Contacte-nos
          </NavLink>

          <NavLink
            to={"para-si"}
            className="items-center justify-center rounded-full transition-all font-medium text-sm 2xl:px-8 2xl:py-2 px-2 py-2 border w-full md:w-fit bg-cocoa-300 md:border-cocoa-300 md:text-black uppercase inline-flex whitespace-nowrap shadow-lg md:shadow-orange-200 hover:shadow-orange-400 hover:shadow-2xl hover:bg-cocoa-400 hover:text-white md:px-8"
          >
            <div className="block text-white">Para Si</div>
          </NavLink>

          <span className="flex-1"></span>
          <a href="https://igrejas.adventistas.org.pt" className="md:hidden text-xs">Encontre uma igreja</a>
          <a href="https://noticias.adventistas.org.pt" className="md:hidden text-xs">Notícias</a>
          <a href="https://institucional.adventistas.org.pt" className="md:hidden text-xs">Institucional</a>
          <a href="https://recursos.adventistas.org.pt" className="md:hidden text-xs">Recursos</a>
          <a href="https://streaming.adventistas.org.pt" className="md:hidden text-xs">Streaming</a>
          <a href="https://institucional.adventistas.org.pt/apps" className="md:hidden text-xs">Apps</a>
        </nav>
      </div>
    </div>
  );
}

export default Header;
