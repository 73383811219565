function YouTube(props) {

return (
    <iframe
    className="aspect-video w-full"
    src={props.url}
    title={props.url}
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowFullScreen
  ></iframe>
)

}

export default YouTube;