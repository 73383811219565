import React from "react";

function MediaBlock(props) {
    return  (
      <div className="mt-24">
      <div className="md:grid md:grid-cols-2 md:gap-12">
        <div className={`${props.align === 'start' ? "justify-start" : "justify-end order-last"}`}>
        {props.media}
        </div>
        <div className="flex flex-col items-start">
          <a className={`${props.small ? "text-lg md:text-xl" : "text-3xl md:text-5xl"} mt-4 font-serif mb-8 md:mt-0 hover:text-orange-900`} href={props.url} rel="noreferrer" target
          ="_blank">{props.title}</a>
          <p className="text-lg">
          {props.description}
          </p>
          {props.showButton && <a className="bg-[#5da8e1] rounded-xl shadow-lg mt-4 inline px-4 py-2 text-sm hover:bg-[#5da8e1] text-white hover:shadow-lg" href={props.url}>Descubra mais...</a>}
        </div>
      </div>
    </div>
    )
}

export default MediaBlock;