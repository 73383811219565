import YouTube from "../components/medias/Youtube";
import { News } from "tabler-icons-react";
export const channels = [
  {
    align: "start",
    title: "TV Novo Tempo Portugal",
    media: <img src="/images/channels/ntp.jpg"/>,
    description: `A TV Novo Tempo Portugal emite exclusivamente pela Internet.
    Todas as semanas temos programação em direto à sexta-feira à noite
    e dos nossos conteúdos temos programas sobre música, educação,
    Bíblia entre muitos outros.`,
    url: "https://www.novotempo.pt",
    showButton: true,
  },
  {
    align: "end",
    title: "Rádio RCS",
    media: (
      <img
        alt="RCS"
        className="aspect-video object-cover"
        src="https://images.unsplash.com/photo-1589903308904-1010c2294adc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80"
      />
    ),
    description: `A Rádio RCS é uma rádio de inspiração cristã que procura
      através dos seus programas sobre Estilo de Vida, Bíblia, Educação, 
      Cultura e música, levar uma mensagem de esperança aos seus ouvintes. 
      A Rádio RCS tem 34 anos de existência e emite a partir da serra de Sintra 
      para a Região da Grande Lisboa em 91.2 FM e para todo o mundo pela
      Internet.`,
    url: "https://radiorcs.novotempo.pt",
    showButton: true,
  },
  {
    align: "start",
    title: "Cursos Novo Tempo Portugal",
    media: <img src="/images/channels/cursos-nt.jpg" />,
    description: `Um estudo autónomo, sem pressões, ao seu ritmo e com
      facilidade de acesso através de qualquer equipamento eletrónico.
      Acesso permanente a um instrutor disponível para esclarecimento
      de dúvidas sobre o curso, partilha de questões Bíblicas e apoio
      espiritual.`,
    url: "https://www.novotempo.pt/escolabiblica",
    showButton: true,
  },
  {
    align: "end",
    title: "Revista Sinais dos Tempos",
    media: <img src="/images/channels/sinais-dos-tempos.jpg" />,
    description: `A Sinais dos Tempos oferece uma perspectiva bíblica e
      cristã sobre a nossa vida e a nossa época. Perante a pluralidade dos
      'sinais' mundiais evidentes, as realidades inquietantes dos nossos dias
      e as incertezas, a ST partilha informação e artigos que transmitem
      segurança e conforto, incentivando a reflexão e a preparação para o
      segundo advento de Cristo à Terra, ao qual a Bíblia chama
      "a feliz esperança" – a única solução viável para a Humanidade!`,
    showButton: true,
  },
  {
    align: "start",
    small: true,
    url: "https://www.revistaadventista.pt",
    title: "Revista Adventista",
    media: <News size={48} strokeWidth={1} />,
    description: `A Revista Adventista é publicada mensalmente pela União Portuguesa dos Adventistas do Sétimo Dia desde 1940.
      A nossa missão é realçar Jesus Cristo usando artigos e ilustrações para demonstrar o Seu amor sem igual, dar as boas-novas do Seu trabalho presente, ajudar outros a conhecê-l’O melhor e manter a esperança da Sua breve vinda.`,
  },
  {
    align: "start",
    title: "Revista Nosso Amiguinho",
    media: <img src="/images/channels/nosso-amiguinho.jpg" />,
    description: `Todos os meses, a revista Nosso Amiguinho vem recheada de
    histórias, receitas saudáveis, brincadeiras, curiosidades e atividades.
    Quem vai querer? Todas as crianças!`,
    url: "http://www.nossoamiguinho.pt/",
    showButton: true,
  },

  {
    align: "start",
    small: true,
    url: "https://dialogue.adventist.org/pt/inicio",
    title: "Diálogo Universitário",
    media: <News size={48} strokeWidth={1} />,
    description: `Diálogo procura alimentar em seus leitores uma fé viva e inteligente, buscando aprofundar seu compromisso com Cristo, a Bíblia e a missão global adventista. Trabalha para dar respostas bíblicas a assuntos contemporâneos referentes a artes, ciências humanas, filosofia, religião e ciências físicas, e apresentar modelos práticos de serviço cristão e alcance.`,
  },
  {
    align: "start",
    small: true,
    url: "https://www.aidlr.org.pt/revista-consciencia-liberdade/",
    title: "Consciência e Liberdade",
    media: <News size={48} strokeWidth={1} />,
    description: `A Associação Internacional para a Defesa da Liberdade Religiosa tem como objectivo divulgar no mundo os princípios desta liberdade fundamental e defender, por todos os meios legítimos, o direito de cada homem a praticar o culto de sua escolha ou de não praticar nenhum.`,
  },
];
