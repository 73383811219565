import YouTube from "../components/medias/Youtube";

export const communityMedias = [
  {
    align: "end",
    title: "Quem são os Adventistas do Sétimo Dia?",
    media: <YouTube url="https://www.youtube.com/embed/2B9kUQNMOoU" />,
    description: `Descubra o essencial sobre quem são os Adventistas em 1 minuto.`,
  },
  {
    align: "start",
    title: "Adventistas pelo Mundo",
    media: <YouTube url="https://www.youtube.com/embed/8WI0ett8gnQ" />,
    description: `Há mais de 22 milhões de Adventistas em 212 países,
    organizados em 13 divisões e quatro uniões. O Brasil possui o maior número
    de membros, com mais de 1,7 milhões, e Portugal tem cerca de 11 mil membros
    distribuídos em cinco regiões. Conheça os números dos adventistas em
    Portugal e no Mundo neste vídeo. `,
  },
  // {
  //   align: "start",
  //   title: "O Nosso Louvor",
  //   media: <YouTube url="https://www.youtube.com/embed/sa4ERycT_kU" />,
  //   description: `Cada Sábado as nossas igrejas recebem os crentes que se juntam
  //     para estudar a bíblia, orar e louvar a Deus. No meio de abraços,
  //     também partilhamos as questões que nos surgiram na semana, e
  //     juntos procuramos respostas inspiradas pela Bíblia`,
  // },
  // {
  //   align: "end",
  //   title: "A nossa família",
  //   media: (
  //     <YouTube url="https://www.youtube.com/embed/OHcybzElmLY?start=5318" />
  //   ),
  //   description: `  Um dos conceitos que mais valorizamos é a comunhão entre os
  //     membros da comunidade religiosa. Baseada na fraternidade cristã,
  //     que se traduz na construção de amizades sólidas e no
  //     estabelecimento de laços afetivos profundos. Essa fraternidade é
  //     importante porque os desafios da vida podem ser superados com
  //     mais facilidade quando se tem o apoio de outras pessoas. Aqui os
  //     membros encontram um ambiente acolhedor, onde são encorajados a
  //     conhecerem-se e a compartilhar as suas experiências. Nesta
  //     família, os membros podem contar com apoio emocional, espiritual
  //     e prático, o que faz toda a diferença na jornada da vida.`,
  // },
  // {
  //   align: "start",
  //   title: "O nosso serviço",
  //   media: <YouTube url="https://www.youtube.com/embed/sa4ERycT_kU" />,
  //   description: `Por meio da ADRA (Agência Adventista de Desenvolvimento
  //       Recursos e Assistência), a igreja realiza ações para ajudar comunidades
  //       em situação de vulnerabilidade, com programas que buscam
  //       promover a educação, a saúde, a segurança alimentar e a
  //       assistência em situações de emergência. Entre as ações
  //       realizadas estão a distribuição de cabazes de alimentos para
  //       famílias em situação de vulnerabilidade, a organização de
  //       workshops sobre temas como família, saúde e finanças pessoais,
  //       além de outras atividades que visam ajudar as pessoas a
  //       superarem as dificuldades do dia a dia. Essa atuação da igreja
  //       mostra o seu compromisso com o bem-estar da comunidade em que
  //       está inserida, e como a sua mensagem pode ir além do âmbito
  //       religioso, buscando transformar positivamente a vida das
  //       pessoas.`,
  // },
  // {
  //   align: "end",
  //   title: "Os nossos Jovens",
  //   media: <YouTube url="https://www.youtube.com/embed/F9usagA7HzM" />,
  //   description: `Damos um especial foco ao desenvolvimento dos jovens,
  //     oferecendo-lhes oportunidades para crescerem física, social e
  //     intelectualmente. Para isso, são oferecidos diversos programas e atividades
  //     que visam proporcionar uma formação integral. Dinamizamos atividades ao ar
  //     livre, em contato com a natureza, como acampamentos. Além disso, existem
  //     programas desportivos que incentivam a prática de atividades físicas e o
  //     trabalho em equipa. Socialmente, a igreja oferece oportunidades para os
  //     jovens se conhecerem e se relacionarem, seja por meio da música, com grupos
  //     e coros, ou por meio de estudos bíblicos e outras atividades que estimulam
  //     a reflexão e a discussão. Um ambiente seguro e acolhedor em que os jovens
  //     podem crescer e desenvolver-se.`,
  // },
  // {
  //   align: "start",
  //   title: "As nossas instituições",
  //   media: <YouTube url="https://www.youtube.com/watch?v=RN8jG_uPR1k" />,
  //   description: `Destacamos a existência de cinco escolas que oferecem uma
  //     educação de qualidade para crianças e jovens, além de quatro
  //     lares que atuam na assistência a idosos, órfãos e pessoas em
  //     situação de vulnerabilidade social. Além disso, a igreja também
  //     possui uma editora que publica livros e outros materiais de
  //     interesse geral, incluindo obras sobre saúde, educação e
  //     religião, entre outros temas. Isto reflete o compromisso da
  //     Igreja em promover a melhoria das condições de vida das pessoas
  //     em todo o mundo.`,
  // },
];
