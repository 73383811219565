import React, { useEffect } from "react";

import FaithSections from "../faith/FaithSections";

import { Timeline } from "@knight-lab/timelinejs";
import "@knight-lab/timelinejs/dist/css/timeline.css";
import events from "../../data/timeline.json";
import { Bible, Eye, IceCream, User } from "tabler-icons-react";

function FaithPage() {
  useEffect(() => {
    const options = {
      language: "pt",
      initial_zoom: 7,
    };

    const timeline = new Timeline("timeline-embed", events, options);
  }, []);

  return (
    <div className="px-4 md:px-40">
      <div className="grid grid-cols-8 mt-12 gap-1 md:gap-8 border-b-2 border-gray-200 pb-16">
        <div className="hidden md:block col-span-2 col-start-8 md:col-start-1 row-start-1 px-0 md:px-8">
          <Bible
            stroke="none"
            className="bg-orange-200 stroke-orange-400 text-white aspect-square w-full h-auto md:h-36 md:w-36 rounded-full md:p-4"
          />
        </div>
        <div className="col-span-8 md:col-span-7 md:col-start-3 row-start-1">
          <h3 className="text-lg font-bold mb-4 uppercase">
            Afirmamos a autoridade da Bíblia como fonte de revelação e
            inspiração da parte de Deus.
          </h3>
          <p>
            Porque confiamos em Deus como Criador e Senhor de todas as coisas,
            temos um compromisso de fidelidade ao que está escrito, que
            fundamenta a nossa fé, orienta a nossa vida e nos enche de alegria.
            Jesus é o centro da nossa fé, e Ele praticou e ensinou uma leitura
            histórica da Bíblia, a literalidade dos seus relatos e a adesão aos
            seus valores. Porque Jesus creu na Palavra, nós cremos na Palavra.
          </p>
          <span className="block mt-4">
            <i>"Santifica-os na verdade; a tua palavra é a verdade." </i>
            <span>João 17:17</span>
          </span>
        </div>
      </div>
      <div className="grid grid-cols-8 mt-16 gap-4 md:gap-8 border-b-2 border-gray-200 pb-16">
        <div className="col-span-8 md:col-span-6 row-start-1">
          <h3 className="text-lg font-bold mb-4 uppercase">
            Acreditamos numa visão holística do ser humano.
          </h3>
          <p>
            Como seres criados à imagem de Deus, a nossa plena realização dá-se
            na harmonia entre as dimensões física, emocional, social e
            espiritual. Professamos uma prática cristã que se concretiza nas
            escolhas do dia a dia e num estilo de vida equilibrado que promove
            felicidade. Temos como desígnio integrar a fé e os valores cristãos,
            na vida pessoal, na família, no trabalho ou nos relacionamentos.
            Porque Jesus nos deixou o modelo de uma fé prática, temperante e
            altruísta, procuramos seguir o seu exemplo.
          </p>
          <span className="block mt-4">
            <i>
              "Aquele que diz que está nele também deve andar como ele andou."{" "}
            </i>
            <span>I João 2:6</span>
          </span>
        </div>
        <div className="hidden md:block col-span-1 md:col-span-2 col-start-1 md:col-start-7 row-start-1 place-content-end px-0 md:px-8">
          <User
            stroke="none"
            className="bg-orange-200 stroke-orange-400 text-white aspect-square w-full h-auto md:h-36 md:w-36 rounded-full md:p-4"
          />
        </div>
      </div>
      <div className="grid grid-cols-8 mt-16 gap-1 md:gap-8 border-b-2 border-gray-200 pb-16">
        <div className="hidden md:block col-span-2 col-start-8 md:col-start-1 row-start-1 px-0 md:px-8">
          <Eye
            stroke="none"
            className="bg-orange-200 stroke-orange-400 text-white aspect-square w-full h-auto md:h-36 md:w-36 rounded-full md:p-4"
          />
        </div>
        <div className="col-span-8 md:col-start-3 row-start-1">
          <h3 className="text-lg font-bold mb-4 uppercase">
            Ancoramos a nossa fé nas promessas de Deus e nas revelações
            proféticas da Sua palavra.
          </h3>
          <p>
            A profecia Bíblica é uma fonte segura para compreendermos
            acontecimentos do passado, a realidade do tempo presente e o que o
            futuro nos reserva. É a profecia que traz luz sobre a origem e a
            realidade de conflito e caos no mundo em que vivemos. É ela também
            que fundamenta a nossa fé na segunda vinda de Jesus à Terra como a
            definitiva solução. Porque Jesus deixou extraordinárias revelações
            sobre a Sua vinda, centramos nelas a nossa esperança.
          </p>
          <span className="block mt-4">
            <i>
              "Eis que cedo venho: Bem-aventurado aquele que guarda as palavras
              da profecia deste livro."{" "}
            </i>
            <span>Apocalipse 22:7</span>
          </span>
        </div>
      </div>

      <h1 className="text-3xl font-serif mt-12 mb-8">As nossas crenças</h1>
      <FaithSections />

      <div id="timeline-embed" style={{ width: "100%", height: "600px" }}></div>

      <h1 className="text-3xl font-serif mt-12 mb-8">As nossas respostas</h1>
      <div className="bg-white w-full divide-y divide-gray-100 mb-12 rounded">
        <details className="open:bg-gray-50 open:shadow-xl open:rounded-xl transition-all">
          <summary className="question py-3 px-4 cursor-pointer select-none w-full outline-none font-medium">
            <span>Os adventistas são protestantes?</span>
          </summary>
          <p className="pt-1 pb-3 px-4">
            Sim, os Adventistas do Sétimo Dia são considerados um grupo
            protestante. Eles são uma denominação cristã que se originou nos
            Estados Unidos no século XIX, como resultado do movimento do Segundo
            Grande Despertar. Os adventistas do sétimo dia compartilham muitas
            das crenças e práticas fundamentais do protestantismo, incluindo a
            crença em um único Deus, a autoridade da Bíblia e a salvação pela
            graça por meio da fé em Jesus Cristo. Eles também seguem a tradição
            protestante de enfatizar o estudo da Bíblia e a importância da
            pregação. No entanto, os adventistas do sétimo dia também têm
            algumas crenças distintas, como a guarda do sábado como dia sagrado
            e a ênfase na saúde e bem-estar. Eles também acreditam na iminência
            do retorno de Jesus Cristo e no julgamento final.
          </p>
        </details>
        <details className="open:bg-gray-50 open:shadow-xl open:rounded-xl transition-all">
          <summary className="question py-3 px-4 cursor-pointer select-none w-full font-medium">
            <span>É preciso ser vegetariano para ser adventista?</span>
          </summary>
          <p class="pt-1 pb-3 px-4">
            Não é necessário ser vegetariano para ser adventista do sétimo dia,
            mas muitos adventistas escolhem seguir uma dieta vegetariana por
            motivos religiosos e de saúde. A crença adventista na importância da
            saúde levou muitos membros a adotarem um estilo de vida saudável,
            que inclui uma dieta vegetariana. Os adventistas acreditam que o
            corpo humano é um templo sagrado de Deus e que devem cuidar dele da
            melhor maneira possível. Além disso, muitos adventistas interpretam
            as instruções dietéticas encontradas no livro de Levítico da Bíblia
            como sendo um guia para uma dieta vegetariana. No entanto, a dieta
            vegetariana não é obrigatória e a escolha de ser vegetariano ou não
            é deixada a critério de cada indivíduo. O importante é que os
            adventistas enfatizam a importância de um estilo de vida saudável e
            equilibrado, que inclua a prática regular de exercícios físicos e a
            abstinência de substâncias nocivas, como álcool e tabaco.
          </p>
        </details>
        <details className="open:bg-gray-50 open:shadow-xl open:rounded-xl transition-all">
          <summary className="question py-3 px-4 cursor-pointer select-none w-full font-medium">
            <span>
              É mesmo verdade que as doutrinas da igreja adventista são apenas
              baseadas Bíblia?
            </span>
          </summary>
          <p class="pt-1 pb-3 px-4">
            A igreja adventista do sétimo dia se considera uma igreja cristã que
            se baseia principalmente na Bíblia para suas doutrinas e crenças.
            Eles acreditam que a Bíblia é a Palavra de Deus e a autoridade final
            em questões de fé e prática.
          </p>
        </details>
        <details className="open:bg-gray-50 open:shadow-xl open:rounded-xl transition-all">
          <summary className="question py-3 px-4 cursor-pointer select-none w-full font-medium">
            <span>
              Quem é Ellen G. White e qual é sua relação com a igreja adventista
              do sétimo dia?
            </span>
          </summary>
          <p class="pt-1 pb-3 px-4">
            Ellen G. White foi uma das fundadoras e líderes da igreja adventista
            do sétimo dia. Ela nasceu em 26 de novembro de 1827 e faleceu em 16
            de julho de 1915. White é considerada pelos adventistas como uma
            profetisa e escritora inspirada por Deus. Acredita-se que Ellen G.
            White recebeu visões divinas e revelações que ela compartilhou com
            outros membros da igreja adventista. Muitas dessas revelações foram
            compiladas em uma série de livros conhecidos como os escritos de
            Ellen G. White, que são considerados pelos adventistas como uma
            fonte inspirada de orientação e ensinamento. Os escritos de Ellen G.
            White são considerados uma parte importante da teologia adventista,
            fornecendo orientação espiritual e prática para os adventistas do
            sétimo dia em sua jornada cristã. Eles são amplamente usados na
            pregação, no ensino e na prática da igreja, mas a Bíblia é
            considerada a fonte final de autoridade e norma de fé e prática.
            Embora a influência de Ellen G. White na igreja adventista tenha
            sido significativa, ela não é adorada ou considerada divina pelos
            adventistas do sétimo dia. Em vez disso, ela é vista como uma
            mensageira inspirada por Deus que ajudou a guiar a igreja em sua
            jornada cristã.
          </p>
        </details>
        <details className="open:bg-gray-50 open:shadow-xl open:rounded-xl transition-all">
          <summary className="question py-3 px-4 cursor-pointer select-none w-full font-medium">
            <span>
              Como é que os adventistas do sétimo dia lidam com a questão da
              ciência e da fé?
            </span>
          </summary>
          <p class="pt-1 pb-3 px-4">
            Os adventistas do sétimo dia acreditam que a ciência e a fé são duas
            maneiras de entender o mundo e que não há contradição entre elas.
            Eles acreditam que a verdadeira ciência e a verdadeira religião não
            podem entrar em conflito, já que ambas buscam compreender o universo
            e a existência humana. Embora a Bíblia seja vista como a fonte final
            de autoridade e guia para a vida cristã, os adventistas do sétimo
            dia também valorizam o estudo cuidadoso da ciência e da razão. Eles
            acreditam que a ciência pode ajudar a iluminar o entendimento da
            Bíblia e a compreensão da criação divina. Os adventistas do sétimo
            dia aceitam as descobertas científicas que estão em harmonia com as
            Escrituras, mas também acreditam que algumas teorias científicas
            podem entrar em conflito com a Bíblia. Eles valorizam a abertura
            para o diálogo e o debate saudável sobre essas questões, buscando
            sempre a verdade e a harmonia entre a fé e a razão. Os adventistas
            do sétimo dia também defendem a preservação do meio ambiente e a
            responsabilidade social, que incluem o cuidado com o planeta e a
            adoção de práticas ecológicas e sustentáveis. A igreja adventista
            promove a educação e a pesquisa científica e incentiva seus membros
            a se envolverem em questões relevantes para a sociedade em geral,
            como a saúde pública e a justiça social.
          </p>
        </details>
      </div>
    </div>
  );
}

export default FaithPage;
