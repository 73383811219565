function FaithSection(props) {
  return (

      <div style={{'--image-url': 'url(' + props.section.image + ")"}}  className="flex flex-col aspect-square md:h-80 rounded overflow-hidden p-12 w-full mx-auto bg-cover bg-center bg-[image:var(--image-url)] relative after:content-[''] after:bg-black after:absolute after:inset-0 after:w-full after:h-full transition-all hover:shadow after:bg-opacity-60 hover:scale-105">
        <h2 className="font-bold text-left pb-4 text-3xl text-white z-10">
          {props.section.title}
        </h2>
        <p className="text-white z-10 text-lg md:text-base">
            {props.section.text}
        </p>
      </div>

  );
}

export default FaithSection;
