import React from "react";

function SmallMediaBlock(props) {
  return (
    <div className="md:grid md:grid-cols-2 gap-4">
      <div className="col-span-2 flex items-center gap-4">
        <div className="bg-slate-500 p-4 aspect-square rounded-full text-white">
          <a href={props.url}>{props.media}</a>
        </div>
        <h2
          className={`${
            props.small ? "text-lg md:text-3xl" : "text-3xl md:text-5xl"
          } font-bold`}
        >
          <a href={props.url} alt="Clicar vai abrir numa nova página" className="underline decoration-dotted decoration-denim-200">{props.title}</a>
        </h2>
      </div>
      <p className="text-lg col-span-2">{props.description}</p>
    </div>
  );
}

export default SmallMediaBlock;
