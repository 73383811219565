import { NavLink } from "react-router-dom";

function CTA() {
    return (
    <div className="flex flex-col md:flex-col py-16 md:p-16 gap-12 place-content-between bg-slate-200 items-center">
      <div className="text-3xl w-2/3 italic leading-relaxed font-serif text-center md:text-center">Queremos ajudá-lo a compreender a Bíblia para encontrar a Liberdade, Restauração e Esperança em Jesus</div>
      <NavLink
            to={"para-si"}
            className="bg-tree-frog-600 text-white uppercase bg-[#5da8e1] transition hover:bg-[#5da8e1] hover:shadow-2xl hover:transform hover:scale-105 px-12 py-6 rounded-lg shadow-xl font-bold text-xl md:mr-10 mr-0 mt-5 md:mt-0 mb-5 md:mb-0"
          >
            Quero!
          </NavLink>
    </div>  
    );
  }
  
  export default CTA;
  