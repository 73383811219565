import React, { useState, useEffect } from "react";
import ContentLoader from "react-content-loader";
import { PlayerPlay } from "tabler-icons-react";
import { register } from "swiper/element/bundle";

function NewsSlider() {
  const [series, setSeries] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const amount = 10;

  useEffect(() => {
    const api = "https://noticias.adventistas.org.pt/wp-json/wp/v2/posts";

    fetch(api)
      .then((response) => response.json())
      .then((data) => {
        setLoaded(true);
        setSeries(data.slice(0, amount));

        const swiperEl = document.querySelector('#news')
        Object.assign(swiperEl, {
          loop: true,
          spaceBetween: 10,
          breakpoints: {
            400: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          },
        });
        swiperEl.initialize();

      });
  }, []);

  return (
    <swiper-container id="news" class="bg-slate-300 p-6">
      {series.map((item) => {
        return (
          <swiper-slide >
               <div className="hover:scale-105 transition saturate-50 hover:saturate-100" title={item.title.rendered}>
                 <a href={item.link} target="_blank" rel="noreferrer">
                    <div className="relative">
                        <img
                            alt={item.title.rendered}
                            className="aspect-video  shadow-lg shadow-slate-400 rounded overflow-hidden"
                            src={item.featured_media_url["pa-block-render"]}
                        />
                   </div>
                   <span className="flex left-0 bottom-0 items-end w-full h-1/2 px-2 py-2 leading-5 sm:text-sm text-slate-800">{item.title.rendered}</span>
                 </a>
               </div>
        </swiper-slide>
        )
      })}

    </swiper-container>
  );
}

export default NewsSlider;
