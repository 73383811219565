import { ChevronLeft, ChevronRight } from "tabler-icons-react";
import { register } from "swiper/element/bundle";
import { useEffect } from "react";

function Slider() {
  const news = [
    // {
    //   image: "/images/news/Timm.jpg",
    //   imageMobile: "/images/news/Timm.mobile.jpg",
    //   imageClass: "object-cover object-[50%_80%]",
    //   description: ``,
    //   link: "https://www.youtube.com/@IASDAlvalade",
    // },
    {
      image: "/images/news/meo_nt-desktop.jpg",
      imageMobile: "/images/news/cursos-mobile.jpg",
      imageClass: "object-cover object-[50%_80%]",
      description: `Cursos Novo Tempo`,
      link: "https://www.novotempo.pt/cursos",
    },
    {
      image: "/images/news/meo_nt-desktop.jpg",
      imageMobile: "/images/news/meo_nt-mobile.jpg",
      imageClass: "object-cover object-[50%_80%]",
      description: `A Novo Tempo Portugal chegou à MEO, canal 186`,
      link: "https://www.novotempo.pt/tv/direto",
    },
    // {
    //   image: "/images/news/Assembleia2023.jpg",
    //   description: `"Eu vou" - E muitos foram.`,
    //   link: "https://noticias.adventistas.org.pt/noticia/eventos/eu-vou-e-muitos-foram/"
    // }
  ];

  useEffect(() => {
    register();
  }, []);

  return (
    <swiper-container pagination="true">
      {news.map((item, index) => (
        <swiper-slide>
          <a href={item.link} target="_blank" rel="noreferrer">
            <picture>
              <source media="(max-width: 480px)" srcSet={item.imageMobile} />
              <source media="(min-width: 480px)" srcSet={item.image} />
              <img
                alt={item.title}
                src={item.image}
                className={item.imageMobile}
              />
            </picture>
          </a>
        </swiper-slide>
      ))}
    </swiper-container>
  );
}

export default Slider;
