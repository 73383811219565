import React, { useState } from "react";
import { BrandWhatsapp, Mail, Phone, Social } from "tabler-icons-react";
import axios from "axios";

export const FORM_STATUS = {
  READY: "ready",
  SUBMITTED: "submitted",
  DONE: "done",
  ERROR: "error",
};

function ContactPage() {
  const [state, setState] = useState({
    name: "",
    email: "",
    message: "",
    submitted: FORM_STATUS.READY,
  });

  const handleSubmit = function (e) {
    e.preventDefault();

    let data = document.querySelector("#form-contact");

    setState((state) => ({
      ...state,
      ...{ submitted: FORM_STATUS.SUBMITTED },
    }));

    // Receba uma biblia
    axios
      .post("https://universitariosadventistas.pt/api/index.php", data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(function () {
        setState((state) => ({ ...state, ...{ submitted: FORM_STATUS.DONE } }));
        e.target.reset();
      });
  };

  return (
    <div className="px-4 md:px-20 mb-32">
      <h1 className="text-4xl mt-12 mb-12 font-bold">Contacte-nos</h1>
      <p className="mb-16">
        Se tiver alguma dúvida, comentário ou feedback, sinta-se à vontade para
        entrar em contato conosco a qualquer momento. Pode usar o formulário
        abaixo, ou contatar-nos por telefone, e-mail ou redes sociais.
        Gostaríamos muito de ouvir a sua história e ajudá-lo com as suas
        questões espirituais. Agradecemos o seu interesse e esperamos entrar em
        contato consigo em breve.
      </p>
      <div className="flex flex-col md:flex-row gap-20">
        <form
          id="form-contact"
          className="w-full md:w-1/2"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="target" value="contact" />
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/4">
              <label
                class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                Nome
              </label>
            </div>
            <div class="md:w-3/4">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-slate-500"
                id="inline-full-name"
                type="text"
                name="name"
              />
            </div>
          </div>
          <div class="md:flex md:items-center mb-6">
            <div class="md:w-1/4">
              <label
                class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                Email
              </label>
            </div>
            <div class="md:w-3/4">
              <input
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cocoa-200"
                id="inline-full-name"
                type="text"
                name="email"
              />
            </div>
          </div>
          <div class="md:flex md:items-start mb-6">
            <div class="md:w-1/4">
              <label
                class="block text-gray-500 font-bold md:text-right mb-1 md:mb-0 pr-4"
                for="inline-full-name"
              >
                Mensagem
              </label>
            </div>
            <div class="md:w-3/4">
              <textarea
                class="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full h-48 py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-cocoa-200"
                id="inline-full-name"
                type="text"
                name="message"
              />
              <button
                type="submit"
                className="bg-[#5da8e1] appearance-none text-white rounded py-2 px-4 transition hover:shadow-lg"
              >
                {state.submitted === FORM_STATUS.SUBMITTED
                  ? "A enviar..."
                  : "Enviar"}
              </button>
              {state.submitted === FORM_STATUS.DONE && (
                <span className="text-green-400 ml-4">Mensagem enviada!</span>
              )}
            </div>
          </div>
        </form>
        <div className="w-full md:w-1/2 grid grid-cols-1 gap-8 md:gap-0 md:grid-cols-2">
          <div className="flex flex-row gap-6">
            <div className="h-20 aspect-square rounded-lg shadow-2xl shadow-slate-500 bg-slate-400 flex justify-center items-center p-6">
              <Phone strokeWidth={1} stroke="white" />
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-xl">Telefone</span>
              <div className="text-md">(+351) 213 510 910</div>
            </div>
          </div>
          <div className="flex flex-row gap-6">
            <div className="h-20 aspect-square rounded-lg shadow-2xl shadow-slate-500 bg-slate-400 flex justify-center items-center p-6">
              <Mail strokeWidth={1} stroke="white" />
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-xl">Email</span>
              <div className="text-md">comunicacoes@adventistas.org.pt</div>
            </div>
          </div>
          <div className="flex flex-row gap-6">
            <div className="h-20 aspect-square rounded-lg shadow-2xl shadow-slate-500 bg-slate-400 flex justify-center items-center p-6">
              <BrandWhatsapp strokeWidth={1} stroke="white" />
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-xl">WhatsApp</span>
              <div className="text-md">933 93 92 91</div>
            </div>
          </div>
          <div className="flex flex-row gap-6">
            <div className="h-20 aspect-square rounded-lg shadow-2xl shadow-slate-500 bg-slate-400 flex justify-center items-center p-6">
              <Social strokeWidth={1} stroke="white" />
            </div>
            <div className="flex flex-col">
              <span className="font-bold text-xl">Redes Sociais</span>
              <div className="text-md">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/igrejaadventistapt"
                >
                  Instagram
                </a>
              </div>
              <div className="text-md">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/igrejaadventistapt"
                >
                  Facebook
                </a>
              </div>
              <div className="text-md">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.youtube.com/@igrejaadventistapt"
                >
                  Youtube
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
