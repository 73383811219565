import React from "react";

import TopNavigation from "../../TopNavigation";
import Header from "../../Header";
import Footer from "../../Footer";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <>
      <TopNavigation />
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout;
