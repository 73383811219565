import { Link } from "react-router-dom";
import FaithSection from "./FaithSection";

import { beliefs } from "../../data/Beliefs";


function FaithSections() {
    return (
        <div className="container mx-auto grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-12 mt-12 mb-12">
            {beliefs.map((b) => (<Link to={b.slug}><FaithSection section={b} /></Link>))}
        </div>
    )
}

export default FaithSections;