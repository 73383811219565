import logo from '../adventist.svg'
import logo_w from '../adventist_w.svg'

function Logo(props) {

    return (
      <img
        className="text-3xl font-bold text-white"
        width="250"
        height="26"
        alt="Logo da Igreja Adventista do Sétimo Dia"
        loading="eager"
        src={props.white ? logo_w : logo}
      />
    );
  }

export default Logo;