import { Link, NavLink } from "react-router-dom";
import { BrandWhatsapp, Phone } from "tabler-icons-react";
import Logo from "./Logo";

function Footer() {

  return (
    <div className="lg:block bg-slate-900 text-white">
      <div className="mx-auto px-4 py-4 md:py-8 md:px-12 lg:px-16 max-w-[2024px]">
        <div className="grid grid-cols-1 md:grid-cols-3 items-start justify-between">
          <Link to={"/"} className="z-10 mx-auto relative font-medium">
            <Logo white />
          </Link>
          <ul className="mt-4 mb-2 md:mt-0 md:mb-0">
            <li><a href="//institucional.adventistas.org.pt/privacidade" target="_blank" rel="noreferrer">Política de Privacidade</a></li>
            <li><NavLink to={"contactos"}>Contacte-nos</NavLink></li>
          </ul>
          <nav
            className="flex flex-col items-start md:items-end gap-4 md:justify-end"
            aria-label="Main"
          >
            <address className="mb-2 md:mt-0 md:mb-0">
              União Portuguesa dos Adventistas do Sétimo Dia
              <br />
              Rua Acácio Paiva, 35
              <br/>
              1700-004 Lisboa
            </address>
            <div className="flex flex-row items-center md:justify-end w-full gap-2">
              <Phone strokeWidth={1} />
              <a href="https://wa.me/+351933939291"><BrandWhatsapp strokeWidth={1}/></a>
              <a href="tel:933939291">933 93 92 91</a>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Footer;
