import React from "react";

import TopNavigation from "../TopNavigation";
import Header from "../Header";
import Footer from "../Footer";

function MissingPage() {
  return (
    <>
      <TopNavigation />
      <Header />
      <div className="container flex flex-col align-middle items-center pt-24 pb-24">
        <span className="text-9xl select-none shadow-2xl rounded-full bg-campfire aspect-square flex items-center justify-center h-48 w-48 text-white font-mono overflow-hidden">!</span>
        <h1 className="text-4xl pb-4 pt-8">Página não encontrada...</h1>
        <span className="text-xl">O link que seguiu não existe ou a página foi eliminada.</span>
      </div>
      <Footer />
    </>
  );
}

export default MissingPage;
