import React from "react";

import Slider from "../Slider";
import CTA from "../CTA";
import NewsSlider from "../NewsSlider";

function MainPage() {
  return (
    <>
      <Slider />
      <NewsSlider />
      <CTA />
    </>
  );
}

export default MainPage;
