import React, { useEffect } from "react";

import { beliefs } from "../../data/Beliefs";

function FaithSectionPage(props) {

  const section = beliefs.filter(function (entry) { return entry.slug === props.section; })[0];

  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }, []);

  return (
    <div className="md:container md:mx-auto md:px-48 pt-10 mb-48">
        <h1 className="text-5xl pb-4 mb-8 border-b-campfire border-b-2">{section.title}</h1>
        <p className="text-xl my-12">{section.text}</p>

        <div className="grid grid-cols-3 gap-8 mt-8">
            {section.beliefs.map((belief) => 
                <a className="transition transform hover:scale-105" href={belief.url} target='_blank'>
                    <img alt={belief.title} className="rounded-lg shadow-2xl" src={belief.image}/>
                    <h2 className="font-bold text-lg mt-2 text-center">{belief.title}</h2>
                    <p className="mt-2 text-sm text-gray-600 text-center">{belief.subtitle}</p>
                </a>
            )}
        </div>

    </div>
  );
}

export default FaithSectionPage;