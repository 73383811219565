import * as React from "react";

function TopNavigation() {
  return (
    <div className="mx-auto pt-4 px-0 md:px-12 lg:px-16 max-w-[2024px] hidden md:block">
      <ul className="flex flex-row gap-4 justify-end uppercase text-slate-600">
        <li><a href="https://igrejas.adventistas.org.pt">Encontre uma igreja</a></li>
        <li><a href="https://noticias.adventistas.org.pt">Notícias</a></li>
        <li><a href="https://institucional.adventistas.org.pt">Institucional</a></li>
        <li><a href="https://recursos.adventistas.org.pt">Recursos</a></li>
        <li><a href="https://streaming.adventistas.org.pt">Streaming</a></li>
        <li><a href="https://institucional.adventistas.org.pt/apps">Apps</a></li>
      </ul>
    </div>
  );
}

export default TopNavigation;
